$(document).on("turbolinks:load", function() {

  if ($(".ct_posts.ac_new")[0]||$(".ct_posts.ac_edit")[0]) {

    $(function() {
      uf_set_inifile();

      $('.btn-submit').on('click', function() { 
        uf_set_inifile();

        $('form#form-post').submit();
      });

      $('.btn-del-file').on('click', function() {
        if ($('#attached_files option:selected')) {
          $('#attached_files option:selected').remove();
        }
      });
    });

    var uf_set_inifile = function() {
      var ini_files = [];

      $('#attached_files option').each(function(i, el) { console.log(el)
        ini_files.push(el.value);
      });

      $('#attached_files_ids').val(ini_files.join(","));
    }

    var uf_file_uploaded = function(aId, aFile) {
      $('#attached_files').append('<option value="'+aId+'">'+aFile+'</option>');

      $('form#new_attachment, form#edit_attachment')[0].reset();
      uf_set_inifile();
    }
  }

});
