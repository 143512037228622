$(document).on("turbolinks:load", function() {
  var get_current_time = function(aDiv="time") {
    var td = new Date();
    var rv = "";

    if(aDiv=="time") {
      var th = td.getHours();
      var tm = "AM";

      if(th>=12) {
        tm = "PM";
        th = th - 12;
        if(th==0) th=12;
      }

      rv = ("0" + th).slice(-2) + ":" + ("0"+td.getMinutes()).slice(-2);
      rv = rv + " " + tm;
    } else {
      rv = td.getFullYear()+"."+("0"+(td.getMonth()+1)).slice(-2)+"."+("0"+td.getDate()).slice(-2);
    }
    
    return rv;
  }



  if ($(".ct_counsellings.ac_new")[0]||$(".ct_counsellings.ac_edit")[0]) {
    $('.select2-single').select2({
      placeholder: '추가할 코드를 선택하세요.'
    });
  
    var counsel_form_initial = function(aDiv) {
      add_badge_event("category");
      add_badge_event("client");
      add_badge_event("agent");
    }

    var client_form_initial = function(aDiv) {
      if(aDiv=="all") {
        initial_proc("agent");
        initial_proc("client");
      }else{
        initial_proc(aDiv);
      }
    }

    var initial_proc = function(aDiv) {
      $('#'+aDiv+'info_disabilities_input').on('select2:select', function (e) {
        // Do something client_disability_list
        var data = e.params.data;
        //var tId = "client";
        //if($(this).attr("id")==aDiv+"info_disabilities_input") tId = aDiv;

        append_item(aDiv, data.text, data.id);
      });

      $('#'+aDiv+'info_device_input, #'+aDiv+'info_communication_input').on('change', function (e) {
        var tId = $(this).attr('id');
        var tVal = $(this).val();

        if(tId==aDiv+'info_device_input') {
          $('#counselling_'+aDiv+'_attributes_device_list').val(tVal);
          $('#counselling_'+aDiv+'_attributes_device_cnt').val(tVal.length);
        }
        if(tId==aDiv+'info_communication_input') {
          $('#counselling_'+aDiv+'_attributes_communication_list').val(tVal);
          $('#counselling_'+aDiv+'_attributes_communication_cnt').val(tVal.length);
        }
      });

      $('#wrap-'+aDiv+' .select2-single').select2({
        placeholder: '추가할 코드를 선택하세요.'
      }); 
    }

    var add_badge_event = function(aDiv) {
      if(aDiv=="category") {
        $('ul.category_list li button.btn-item-del').on('click', function() { remove_item_category(this) });

        $('#counsel_results_input').on('change', function (e) {
          // Do something client_disability_list
          // var data = e.params.data;
          // append_item(data.text, data.id);
          var tId = $(this).attr('id');
          var tVal = $(this).val();
          
          if(tId=='counsel_results_input') {
            $('#counselling_result_list').val(tVal);
            $('#counselling_result_cnt').val(tVal.length);
          }
        });
      } else {
        $('ul.'+aDiv+'_disability_list li button.btn-item-del').on('click', function() { remove_item(this) });  
      }
    }

    var set_select_val = function(aDiv) {
      var tVal = "";
      var tCnt = 0;
      $.each( $('ul.'+aDiv+'_disability_list li'), function(idx, val) {
        tVal = tVal + (tVal==''?'':',') + $(val).attr('data-id');
        tCnt++;
      });

      $('#counselling_'+aDiv+'_attributes_disability_list').val(tVal);
      $('#counselling_'+aDiv+'_attributes_disability_cnt').val(tCnt);
    }

    var set_select_val_category = function(aDiv) {
      var tVal = "";
      var tCnt = 0;
      $.each( $('ul.category_list li'), function(idx, val) {
        tVal = tVal + (tVal==''?'':',') + $(val).attr('data-id');
        tCnt++;
      });

      $('#counselling_category_list').val(tVal);
      $('#counselling_category_cnt').val(tCnt);
    }

    var append_item = function(aDiv, aTxt, aIdx) { 
      var disabs = $('#counselling_'+aDiv+'_attributes_disability_list').val().split(","); 

      if( $.inArray( aIdx , disabs) < 0) {
        //disabs
        var tHtml = '<li data-id="'+aIdx+'"><span class="badge badge-secondary">'+aTxt.replace("장애","")+' <button type="button" class="btn btn-xs btn-secondary btn-item-del" ><i class="mdi mdi-close-thick"></i></button></span></li>';

        $('ul.'+aDiv+'_disability_list').append(tHtml);
        $('ul.'+aDiv+'_disability_list li button.btn-item-del').on('click', function() { remove_item(this) });
        set_select_val(aDiv);
      }
    }

    var append_item_category = function(aTxt, aIdx) {
      var catgs = $('#counselling_category_list').val().split(",");
      if( $.inArray( aIdx , catgs) < 0) {
        //catgs
        var tHtml = '<li data-id="'+aIdx+'"><span class="badge badge-secondary">'+aTxt+' <button type="button" class="btn btn-xs btn-secondary btn-item-del" ><i class="mdi mdi-close-thick"></i></button></span></li>';

        $('ul.category_list').append(tHtml);
        $('ul.category_list li button.btn-item-del').on('click', function() { remove_item_category(this) });
        set_select_val_category();
      }
    }

    var remove_item = function(aObj) {
      $(aObj).parent().parent().remove();
      set_select_val();
    }

    var remove_item_category = function(aObj) {
      $(aObj).parent().parent().remove();
      set_select_val_category();
    }

    var uf_toggle = function(aId) {
      $("#wrap-"+aId).toggle("d-none");
    }

    var uf_reset_client = function(a_type) {
      if(confirm((a_type=="agent"?"[내담자]":"[당사자]")+'정보를 초기화 합니다. 계속하시겠습니까?')) {
        var tUrl = '/counsellings/new';

        $.ajax({
          type: 'GET',
          data: {layout: 'blank'},
          dataType: 'html',
          url: tUrl
        }).done(function(html) {
          var p_html = $(html).find('#wrap-'+a_type).html();

          $("#wrap-"+a_type).html(p_html).promise().done(function() {
            client_form_initial(a_type);
          });

          
        });
      }
    }

    var uf_set_self = function(a_type) {
      if(a_type=="본인") {
        $(".self-field,.msg-client-dup").removeClass("d-none");
        $("#wrap-client").addClass("self-counsel opacity-2");
      } else {
        $(".self-field,.msg-client-dup").addClass("d-none");
        $("#wrap-client").removeClass("self-counsel opacity-2");
      }
    }

    $('#counselling_receipt_date').datetimepicker({
      format: 'L'
    });
    $('#counselling_receipt_date').val(get_current_time("date"));

    $('#counselling_cslogs_attributes_0_counsel_date').val(get_current_time("date"));
    $('#counselling_cslogs_attributes_0_start_time').val(get_current_time("time"));

    $('#counselling_cslogs_attributes_0_counsel_date').datetimepicker({
      format: 'L'
    });

    $('#counselling_cslogs_attributes_0_start_time').datetimepicker({
      format: 'LT'
    });

    $('#counselling_cslogs_attributes_0_counsel_min').val(30);
    $('#counselling_cslogs_attributes_0_counsel_count').val(1);

    $('#btn-reset-client,#btn-reset-agent').on('click', function() {
      var tId = $(this).attr("id");

      uf_reset_client(tId.replace("btn-reset-", ""));
    }); 

    $('#btn-toggle-client,#btn-toggle-agent').on('click', function() {
      var tId = $(this).attr("id");

      uf_toggle(tId.replace("btn-toggle-", ""));
    }); 

    $('#counsel_categories_input').on('select2:select', function (e) {
      // Do something client_disability_list
      var data = e.params.data;

      append_item_category(data.text, data.id);
    });

    $("#counselling_client_attributes_age").on("change", function() {
      var tDt = new Date();

      $("#counselling_client_attributes_birth_year").val( tDt.getFullYear() - Number($(this).val()) + 1 );
    });

    $("#counselling_client_attributes_birth_year").on("change", function() {
      var tDt = new Date();

      $("#counselling_client_attributes_age").val( tDt.getFullYear() - Number($(this).val()) + 1 );
    });

    $("#counselling_agent_attributes_age").on("change", function() {
      var tDt = new Date();

      $("#counselling_agent_attributes_birth_year").val( tDt.getFullYear() - Number($(this).val()) + 1 );
    });

    $("#counselling_agent_attributes_birth_year").on("change", function() {
      var tDt = new Date();

      $("#counselling_agent_attributes_age").val( tDt.getFullYear() - Number($(this).val()) + 1 );
    });

    $("#btn-agent-relation input:radio").on('change', function() {
      var rval = $(this).val();

      uf_set_self(rval);
    });

    if($("input[name='counselling[relation]']:checked").val()=="본인") uf_set_self("본인");

    counsel_form_initial("all");
    client_form_initial("all");

    // var uf_reset_client = function(a_type) {
    //   $("#counselling_"+a_type+"_attributes_id").val( "" );
    //   $("#counselling_"+a_type+"_attributes_name").val( "" );
    //   $("#counselling_"+a_type+"_attributes_age_band").val( "파악안됨" );
    //   $("#counselling_"+a_type+"_attributes_sex").val( "모름" );
    //   $("#counselling_"+a_type+"_attributes_hp").val( "" );
    //   $("#counselling_"+a_type+"_attributes_tel").val( "" );
    //   $("#counselling_"+a_type+"_attributes_email").val( "" );

    //   $("#counselling_"+a_type+"_attributes_comm_status").val( "" );
    //   $("#counselling_"+a_type+"_attributes_comm_device").val( "" );
    //   $("#counselling_"+a_type+"_attributes_address1").val( "" );
    //   $("#counselling_"+a_type+"_attributes_remark").val( "" );

    //   var t_dis_yn = "파악안됨";
    //   var t_dis_regist = "파악안됨";
      
    //   $("input[name='counselling["+a_type+"_attributes][disability_yn]'][value='"+t_dis_yn+"']").click();
    //   $("input[name='counselling["+a_type+"_attributes][dis_regist]'][value='"+t_dis_regist+"']").click();
      
    //   $("#counselling_"+a_type+"_attributes_disability1").val( "파악안됨" );
    //   $("#counselling_"+a_type+"_attributes_disability2").val( "파악안됨" );
    //   $("#counselling_"+a_type+"_attributes_disability1,#counselling_"+a_type+"_attributes_disability2").trigger("change");

    //   if(a_type=="agent") {
    //     $("input[name='counselling[duty_yn]'][value='아니요']").click();
    //     $("#counselling_duty_category1").val( "" );
    //     $("#counselling_duty_category2").val( "" );
    //     $("#counselling_duty_category1,#counselling_duty_category2").trigger("change");
    //     $("#counselling_perception").val("");
    //   } else {
    //     $("#counselling_client_attributes_age").val( "" );
    //     $("#counselling_client_attributes_birth_year").val( "" );
    //     $("#counselling_client_attributes_assumption").val( "" );
    //     $("input[name='counselling[residence]']").attr( "checked", false ).parent().removeClass("active");
    //     $("input[name='counselling[income_type]']").attr( "checked", false ).parent().removeClass("active");
    //     $("#counselling_residence,#counselling_income_type").trigger("change");
    //   }
    // }

    // persisted 일때, 
    // $('.select2-single').select2({
    //   placeholder: '추가할 코드를 선택하세요.'
    // });

    // $('#counsel_categories_input').on('select2:select', function (e) {
    //   // Do something client_disability_list
    //   var data = e.params.data;
    //   append_item(data.text, data.id);
    // });

    // $('#counsel_discriminations_input, #counsel_results_input').on('change', function (e) {
    //   // Do something client_disability_list
    //   // var data = e.params.data;
    //   // append_item(data.text, data.id);
    //   var tId = $(this).attr('id');
    //   var tVal = $(this).val();
      
    //   if(tId=='counsel_discriminations_input') {
    //     $('#counselling_discrimination_list').val(tVal);
    //     $('#counselling_discrimination_cnt').val(tVal.length);
    //   }
    //   if(tId=='counsel_results_input') {
    //     $('#counselling_result_list').val(tVal);
    //     $('#counselling_result_cnt').val(tVal.length);
    //   }
    // });

    // $('.has_subjective').on('select2:select', function (e) {
    //   // Do something client_disability_list
    //   var sdata = e.params.data.text;
    //   var tId = $(this).attr('id')+'_etc';

    //   if(sdata=='기타') {
    //     $('#'+tId).prop('readonly', false).focus();
    //   } else {
    //     $('#'+tId).val('').prop('readonly', true);
    //   }
    // });

    // $("input[type=radio][name='counselling[counselling_job_attributes][current_working]']").on('change', function() {
    //   if($(this).val()=='YES') {
    //     $('.cur-working').removeClass('d-none');
    //     $('.cur-notworking').addClass('d-none');
    //   } else if($(this).val()=='NO') {
    //     $('.cur-working').addClass('d-none');
    //     $('.cur-notworking').removeClass('d-none');
    //   } else {
    //     $('.cur-working,.cur-notworking').addClass('d-none');
    //   }
    // });

    // if('#{(counselling.counselling_job.present? ? counselling.counselling_job.current_working : "")}'=='UNKNOWN') {
    //   $('.cur-working,.cur-notworking').addClass('d-none');
    // }
    // if('#{(counselling.counselling_job.present? ? counselling.counselling_job.current_working : "")}'=='YES') {
    //   $('.cur-notworking').addClass('d-none');
    // }
    // if('#{(counselling.counselling_job.present? ? counselling.counselling_job.current_working : "")}'=='NO') {
    //   $('.cur-working').addClass('d-none');
    // }

    // $('.chk-work-category').on('click', function() {
    //   set_work_category();
    // });  

  }



  if ($(".ct_counsellings.ac_show")[0]) {
    var cur_cslog = "";

    var uf_show = function(aId, aPass) {
      var tContinew = 'Y';

      if(aPass=='N' && $('#is_changed').val() == 'Y') {
        $("#confirmModal").modal("show");
      } else {
        if(tContinew=='Y') {
          var tUrl = '/cslogs/'+aId;

          $.ajax({
            type: 'GET',
            data: {page: '#{@cslogs.current_page}'},
            dataType: 'html',
            url: tUrl
          }).done(function(html) {
            $('#wrap-form-cslog').html( html ).promise().done(function() {
              uf_initial_cslog();
            });
            
            uf_set_title('내용');
            
            $("#confirmModal").modal("hide");
            $("#list-cslogs tr").removeClass("bg-gray1");
            if(aId>0) $("#list-cslogs tr[data-id="+cur_cslog+"]").addClass("bg-gray1");
          });
        }    
      }
    }

    var uf_edit = function(aId, aPass, aCid, aPage) {
      var tContinew = 'Y';

      if(aPass=='N' && $('#is_changed').val() == 'Y') {
        $("#confirmModal").modal("show");
      } else {
        if(tContinew=='Y') {
          var tUrl = '/cslogs/'+aId+'/edit';
          if(aId==0) tUrl = '/cslogs/new';

          $.ajax({
            type: 'GET',
            data: {csid: aCid, page: aPage},
            dataType: 'html',
            url: tUrl
          }).done(function(html) {
            $('#wrap-form-cslog').html( html ).promise().done(function() {
              uf_initial_cslog();
            });
            uf_set_title( (aId==0?'새 상담일지':'수정') );
            
            $("#confirmModal").modal("hide");
            // $("#list-cslogs tr").removeClass("bgc-grey-200");
            // if(aId>0) $("#list-cslogs tr[data-id="+cur_cslog+"]").addClass("bgc-grey-200");
          });
        }    
      }
    }

    var uf_set_title = function(aTitle) {
      $('#detail-title').html(aTitle);
    }

    var uf_saved = function() {
      $('#messageModal').modal('show')
      //alert("저장했습니다.")
    }

    var uf_initial = function() {
      $("#list-cslogs tr").on("click", function() {
        var tId = $(this).attr("data-id");
        cur_cslog = tId;

        // uf_edit(tId, "N");
        uf_show(tId, "N");
      });

      $(".btn-notsave").on("click", function() {
        uf_edit(cur_cslog, "Y");
      });

      $(".btn-save").on("click", function() {
        $('#btn-save').trigger('click');
        uf_edit(cur_cslog, "Y");
      });

      $('#btn-new-cslog').on("click", function() {
        var tCid = $(this).attr("data-cid");
        uf_edit(0, 'N', tCid, 1);
      })

      uf_initial_cslog();
    }

    var uf_initial_cslog = function() {
      var td = new Date();

      if('#{(cslog.persisted? ? "edit" : "new")}'=='new') {
        $('#cslog_counsel_date').val(get_current_time("date"));
        $('#cslog_start_time').val(get_current_time("time"));
      }

      $('#cslog_counsel_date').datetimepicker({
        format: 'L'
      });

      $('#cslog_start_time').datetimepicker({
        format: 'LT'
      });

      $('#cslog_counsel_min').val(30);
      $('#cslog_counsel_count').val(1);
      
      
      $('#wrap-form-cslog form input,#wrap-form-cslog form textarea').on('change', function() {
        $('#is_changed').val('Y');
      });

      $('#btn-edit-cslog').on("click", function() {
        var tId = $(this).attr("data-id");
        uf_edit(tId, 'N')
      });
    }

    uf_initial();
  }

  if ($(".ct_counsellings.ac_move")[0]) {
    $('.select2-single').select2({
      placeholder: '추가할 코드를 선택하세요.'
    });

    $('#center_search').on('change', function (e) {
      var tId = $(this).attr('id');
      var tVal = $(this).val();
      var tText = $("option:selected", this).text();

      if(tId=='center_search') {
        $('#center_id').val(tVal);
        $('#center_name').val(tText);
      }
    });
  }
});

